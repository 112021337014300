// @flow

import { type Option, fromNullable } from 'fp-ts/lib/Option';
import { option, array } from 'fp-ts';
import { notEquals, throwWhen } from './functions';
import { FIXED, type StyleRestriction, SUPPORTED_FONT_SIZE } from '../types/style';
import { __DEV__ } from './constants';
import { deriveNumberFromString, matchNumberInRange, trunc } from './numbers';

export type FontStyle = 'italic' | 'bold';

export type WeightMap = {
  bold?: number,
  italic?: number,
  normal?: number,
};

export type FontOption = {
  label: string,
  value: string,
  alias: string,
  className: string,
  availableStyles: Array<FontStyle>,
  weightMap?: WeightMap,
};

export const SUPPORTED_CHARS_ADDRESSING = [
  '/',
  '&',
  ',',
  '-',
  '.',
  ' ',
  '#',
  "'",
  '+',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  ':',
  '@',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  '_',
  '`',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

export const FINO_FONT_ALIAS = 'fino';
export const MOON_CHILD_FONT_ALIAS = 'Moon Child';
export const UPTON_FONT_ALIAS = 'upton';

// Array of font options.
export const fontOptions: Array<FontOption> = [
  {
    label: 'Mark Pro Heavy',
    value: "'Mark Pro Heavy', sans-serif",
    alias: 'markpro',
    className: 'font-family--MarkProHeavy',
    availableStyles: [],
  },
  {
    label: 'Proxima Nova',
    value: "'proxima-nova', 'Proxima Nova', sans-serif",
    alias: 'proxima',
    className: 'font-family--ProximaNova',
    availableStyles: ['bold'],
  },
  {
    label: 'Libre Baskerville',
    value: "'Libre Baskerville', serif",
    alias: 'libre',
    className: 'font-family--LibreBaskerville',
    availableStyles: ['italic', 'bold'],
  },
  {
    label: 'Fortescue',
    value: "'Fortescue', serif",
    alias: 'fortescue',
    className: 'font-family--Fortescue',
    availableStyles: ['italic', 'bold'],
  },
  {
    label: 'Canela',
    value: "'Canela', serif",
    alias: 'canela',
    className: 'font-family--Canela',
    availableStyles: ['italic', 'bold'],
    weightMap: {
      bold: 600,
      italic: 300,
      normal: 300,
    },
  },
  {
    label: 'Sequel Rounded',
    value: "'Sequel Rounded', serif",
    alias: 'sequel',
    className: 'font-family--SequelRounded',
    availableStyles: [],
  },
  {
    label: 'Fino Sans',
    value: "'Fino Sans', sans-serif",
    alias: FINO_FONT_ALIAS,
    className: 'font-family--FinoSans',
    availableStyles: ['italic', 'bold'],
    weightMap: {
      bold: 300,
      italic: 200,
      normal: 200,
    },
  },
  {
    label: 'Helvetica',
    value: "'HelveticaAU', sans-serif",
    alias: 'helvetica',
    className: 'font-family--Helvetica',
    availableStyles: ['italic', 'bold'],
    weightMap: {
      bold: 700,
      italic: 400,
      normal: 400,
    },
  },
  {
    label: 'Helvetica Bold',
    value: "'HelveticaAU-Bold', 'HelveticaAU', sans-serif",
    alias: 'helvetica-bold',
    className: 'font-family--Helvetica-Bold',
    availableStyles: [],
  },
  {
    label: 'Times New Roman',
    value: "'TimesNRAU', serif",
    alias: 'timesnrau',
    className: 'font-family--TimesNewRoman',
    availableStyles: ['italic', 'bold'],
    weightMap: {
      bold: 700,
      italic: 400,
      normal: 400,
    },
  },
  {
    label: 'Didot',
    value: "'Didot', serif",
    alias: 'didot',
    className: 'font-family--Didot',
    availableStyles: [], // add 'bold' to re-add bold
    weightMap: {
      // bold: 700, // uncomment me to re-add bold
      normal: 400,
    },
  },
  {
    label: 'Sabon',
    value: "'Sabon', serif",
    alias: 'sabon',
    className: 'font-family--Sabon',
    availableStyles: [], // add 'bold' to re-add bold
    weightMap: {
      normal: 400,
    },
  },
  {
    label: 'Miller Display',
    value: "'Miller Display', serif",
    alias: 'miller',
    className: 'font-family--MillerDisplay',
    availableStyles: ['bold'],
    weightMap: {
      bold: 600,
      italic: 400,
      normal: 400,
    },
  },
  {
    label: 'Upton',
    value: "'Upton', sans-serif",
    alias: UPTON_FONT_ALIAS,
    className: 'font-family--Upton',
    availableStyles: ['italic', 'bold'],
    weightMap: {
      bold: 600,
      italic: 400,
      normal: 400,
    },
  },
  {
    label: 'Onyx',
    value: "'Onyx', serif",
    alias: 'onyx',
    className: 'font-family--Onyx',
    availableStyles: ['bold'],
    weightMap: {
      normal: 400,
    },
  },
  {
    label: 'Amora',
    value: "'Amora', serif",
    alias: 'amora',
    className: 'font-family--Amora',
    availableStyles: ['bold'],
    weightMap: {
      normal: 400,
    },
  },
  {
    label: 'Monotype Lydian',
    value: "'Monotype Lydian', sans-serif",
    alias: 'monotypeLydian',
    className: 'font-family--MonotypeLydian',
    availableStyles: ['bold'],
    weightMap: {
      normal: 400,
    },
  },
  {
    label: 'Moon Child',
    value: "'Moon Child', sans-serif",
    alias: MOON_CHILD_FONT_ALIAS,
    className: 'font-family--MoonChild',
    availableStyles: [],
    weightMap: {
      normal: 400,
    },
  },
];

export const getFontOption = (value: string): Option<FontOption> =>
  fromNullable(fontOptions.filter((x) => x.value === value || x.alias === value.toLowerCase())[0]);

export const setFontWeight =
  (styles: Array<string>) =>
  (fontFamily: string): Option<number> =>
    getFontOption(fontFamily)
      .filter((x) => 'availableStyles' in x)
      .filter((x) => x.availableStyles.length !== 0)
      .chain((x) => fromNullable(x.weightMap))
      .chain((x) => (styles.length !== 0 ? styles.map((y) => y.toLowerCase()).map((y) => fromNullable(x[y]))[0] : fromNullable(x.normal)));

// getRestrictedFontFamilies takes a restriction and returns the valid fonts from fontOptions
export const getRestrictedFontFamilies = (restriction: StyleRestriction): Option<Array<FontOption>> =>
  option
    .fromPredicate(notEquals(FIXED))(restriction)
    .chain(option.fromNullable)
    // Throw in dev if restriction is not fixed or array or undefined
    .map(
      throwWhen("Font family restriction provided is not a valid value. Must be one of `Array` or `'FIXED'`.")(
        (x) => __DEV__ && !Array.isArray(x)
      )
    )
    // Now that we know it's not FIXED, double check that it's an array
    .chain(option.fromPredicate(Array.isArray))
    // Filter out by restrictions that match a font option
    .map((x) => x.map(getFontOption))
    // flatten
    .map(array.catOptions)
    // Make sure it's still not an empty array
    .map(
      throwWhen(`Font family restriction was not able to filter all fonts provided: ${restriction ? restriction.toString() : 'undefined'}`)(
        (x) => __DEV__ && (x.length === 0 || x.length !== restriction.length)
      )
    )
    // Fall back to all font options if not FIXED
    .alt(notEquals(FIXED)(restriction) ? option.some(fontOptions) : option.none);

// Given a font size string, return corresponding point value
export const fontPxToPtMatcher = (val: String) =>
  deriveNumberFromString(val)
    .chain(matchNumberInRange([0, 70], '14pt', [71, 140], '43pt', [141, 1000], '96pt'))
    .getOrElseValue(val.replace('px', 'pt'));

export const calcPxToPt = (x: number) => trunc((x * 72) / 96);

// Converts a string representation of a font size in pixels, into its point value equivalent
// If the resulting point size is not supported, falls back to the next supported point size
// ex: 100px => 75pt (not supported) => 72pt (supported)
export const convertPxToPts = (val: string) =>
  deriveNumberFromString(val)
    // calculate px to pt value
    .map((x) => calcPxToPt(x))
    // match with a supported point value, if not, fall back to the next closest size
    .chain((x) => option.fromNullable(SUPPORTED_FONT_SIZE.find((y) => x >= y)))
    // prettify to render in font options list
    .map((x) => `${x}pt`)
    .getOrElseValue(val.replace('px', 'pt'));
